import styled from "styled-components";

export const MenuLogo = styled.img`
    width: 225px;
    height: 49px;
    object-fit: cover;
    margin: 48px 0px 96px 12px;
    @media(max-width: 1065px){
        width: 180px;
        height: 39px;
        margin: 56px 0px 56px 18px;
    }
`
export const MenuIcon = styled.img`
    height: 24px;
    padding-right: 15px;
`
export const MenuMobLogo = styled.img`
    width: 300px; 
    height: 49px;
    object-fit: contain;
    margin: 30px 0px 30px 0px;
`