import './App.css';
import {
  Routes,
  Route,
  useNavigate
} from 'react-router-dom';
import { Suspense, lazy, useEffect, useState } from "react";
import moment from 'moment';
import Payments from './pages/Payments';

const Login = lazy(() => import("./pages/Login"));
const Accounts = lazy(() => import("./pages/Accounts"));
const Platform = lazy(() => import("./pages/Platform"));
const Localization = lazy(() => import("./pages/Localization"));
const Blog = lazy(() => import("./pages/Blog"));
const NewPost = lazy(() => import("./pages/NewPost"));
const Templates = lazy(() => import("./pages/Templates"));
const EditTemplate = lazy(() => import("./pages/EditTemplate"));

function App() {

  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(localStorage.getItem('access-token'));

  useEffect(() => {
    const expireDate = localStorage.getItem('expireDate');
    const accessToken = localStorage.getItem('access-token');
    console.warn('accessToken', accessToken);
    console.warn('expireDate', expireDate);
    if (accessToken) {
      if (expireDate) {
        const currentTime = Date.now();
        console.warn('expire and current', moment(new Date(Number(expireDate))).format('YYYY-MM-DD h:mm:ss a'), moment(currentTime).format('YYYY-MM-DD h:mm:ss a'));
        if (currentTime - Number(expireDate) > 43200000) { //if "remember me" not checked logout after 12 hours
          console.warn('clear storage');
          localStorage.clear();
          setToken(null);
          navigate("/");
        }
      }
    }
  }, []);

  useEffect(() => {
    function storageEventHandler() {
      const accessToken = localStorage.getItem('access-token');
      setToken(accessToken);
    }
    window.addEventListener("storage", storageEventHandler);
    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  return (
    <Routes>
      <Route path="/" element={
        token ? <Suspense>
          <Accounts />
        </Suspense> :
          <Suspense>
            <Login />
          </Suspense>
      } />
      <Route path="/platform" element={
        <Suspense>
          <Platform />
        </Suspense>} />
      <Route path="/localization" element={
        <Suspense>
          <Localization />
        </Suspense>} />
      <Route path="/blog" element={
        <Suspense>
          <Blog />
        </Suspense>} />
      <Route path="/templates" element={
        <Suspense>
          <Templates />
        </Suspense>} />
      <Route path="/edit-template/:id" element={
        <Suspense>
          <EditTemplate />
        </Suspense>} />
      <Route path="/post/:id" element={
        <Suspense>
          <NewPost />
        </Suspense>} />
      <Route path="/payments" element={
        <Suspense>
          <Payments />
        </Suspense>} />
    </Routes>
  );
}

export default App;
